<template>
  <div class="box-div">
      <div v-if="styleFlag == 'dashed'" class="legend">
        <div class="line" :style="getLineStyle"></div>
        <div class="circle" :style="getCircleStyle"></div>
        <div :style="getLineStyle" class="line"></div>
      </div>
      <div v-else class="legend">
        <div class="line-base" :style="getBaseLineStyle"></div>
        <div class="circle" :style="getCircleStyle"></div>
        <div :style="getBaseLineStyle" class="line-base"></div>
      </div>
  </div>
</template>

<script>
export default {
  props: [
    "styleFlag",
    "lineColor",
    "circleColor",
  ],
  data() {
    return {
    };
  },
  computed: {
    getLineStyle() {
      return {
        "margin-left": "2px",
        "background-image": `linear-gradient(to right, ${this.lineColor} 80%, rgba(255,255,255,0) 0%)` /* 50%设置虚线点x轴上的长度 */,
      };
    },
    getCircleStyle() {
        return {
            background: this.circleColor
        }
    },
    getBaseLineStyle() {
      return {
        background: this.lineColor
      }
    }
  },
};
</script>

<style lang="less" scoped>
.box-div {
  display: inline-block;
  .legend {
    display: flex;
    align-items: center;

    .circle {
      display: inline-block;
      width: 13px;
      height: 13px;
      border: 1px solid #666666;
      box-sizing: border-box;
      border-radius: 50%;
      vertical-align: middle;
    }
    .line-base {
      display: inline-block;
      width: 51px;
      /*transform: translate(200px,300px)rotate(0deg);*/
      // transform-origin: center;
      height: 1.5px;
      // border-radius: 6px;
    }
    .line {
      width: 50px;
      height: 2px;
      background-position: bottom; /* top配置上边框位置的虚线 */
      background-size: 10px 2px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
      background-repeat: repeat-x;
    }
  }
}
</style>
