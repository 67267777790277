<template>
  <div>
    <div class="box" :style="{right: rightFlag? '441px' : '52px'}" v-drag>
      <div class="close-button" @click="closeCop">
        <i class="el-icon-circle-close"></i>
      </div>
      <div style="font-weight: bold">轨 迹 对 比 船 舶 列 表</div>
      
      <div class="line">
        <div class="lable" style="color:#ee6666">{{ shipList[0] }}</div>
        <legend-line styleFlag="line" circleColor="#ee6666" lineColor="#ee6666" />
        <el-switch class="switch"
          v-model="item0"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="itemChange"
          v-show="!false"
        >
        </el-switch>
      </div>

      <div class="line">
        <div class="lable" style="color:#3066ff">{{ shipList[1] }}</div>
        <legend-line styleFlag="line" circleColor="#3066ff" lineColor="#3066ff" />
        <el-switch class="switch"
          v-model="item1"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="itemChange"
          v-show="!false"
        >
        </el-switch>
      </div>
      
      <div class="line" v-if="shipList.length >=3">
        <div class="lable" style="color:#3ba272">{{ shipList[2] }}</div>

        <legend-line  styleFlag="line" circleColor="#3ba272" lineColor="#3ba272" />

        <el-switch class="switch"
          v-model="item2"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="itemChange" 
          v-show="!false"
        >
        </el-switch>
      </div>

      <div class="line" v-if="shipList.length > 3">
        <div class="lable" style="color:#9a60b4">{{ shipList[3] }}</div>

        <legend-line  styleFlag="line" circleColor="#9a60b4" lineColor="#9a60b4" />

        <el-switch class="switch"
          v-model="item3"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="itemChange" 
          v-show="!false"
        >
        </el-switch>
      </div>

            
    </div>
  </div>
</template>

<script>
import vDrag from "../utils/drag.js";
import LegendLine from "./legendLine.vue";
export default {
  components: {
    LegendLine,
  },
  props: ["shipList", "rightFlag"],
  // props: ["mixValue1", "aisValue1", "opsValue1"],
  data() {
    return {
      item0: true,
      item1: true,
      item2: true,
      item3: true
    };
  },
  computed: {
    getLineStyle() {
      return {
        "margin-left": "-2px",
        "background-image": `linear-gradient(to right, ${this.lineColor} 50%, rgba(255,255,255,0) 0%)` /* 50%设置虚线点x轴上的长度 */,
      };
    },
    getCircleStyle() {
      return {
        background: this.circleColor,
      };
    },
  },
  methods: {
    itemChange(newValue) {
      console.log([this.item0, this.item1, this.item2, this.item3]);
      console.log(this.shipList.length);
      this.$emit('updateCopButton', [this.item0, this.item1, this.item2, this.item3], this.shipList.length)
    },
    closeCop() {
      this.$emit('closeCop')
    }
  },
  mounted() {
    this.mixChange(true); 
    if(!this.shipList){
      this.shipList = ["测试1","测试2"]
    }
  },
  directives: {
    drag: vDrag,
  },
};
</script>

<style lang="less" scoped>
.box {
  position: absolute;
  top: 52px;
  right: 52px;
  width: 300px;
  // height: 200px;
  padding: 30px;
  text-align: center;
  color: aliceblue;
  font-size: 17px;
  background-color: rgba(0, 0, 0, 0.56);
  z-index: 99999;
    box-shadow: -3px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray,
          2px 0 3px -1px gray;
    // box-shadow: 8px 8px 5px #888888;
    border-radius: 10px;

  .lable{
    width: 130px;
    font-size: 13px;
    margin-right: 20px;
    backgroud-color: #fff;
    font-weight: bolder;
    color: #fff !important;
  }

  .line {
    margin-top: 30px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .switch {
      margin-left: 30px;
    }
  }
  .close-button {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 9277;
    font-size: 25px;
    color: #fff;
  }
}
</style>
