function drag(el,binding,vnode){
    let clientOffset = {};
    el.onmousedown = function(e) {
      var disx = e.pageX - el.offsetLeft
      var disy = e.pageY - el.offsetTop
      clientOffset.clientX = event.clientX;
      clientOffset.clientY = event.clientY;
      document.onmousemove = function (e) {
        el.style.left = e.pageX - disx + 'px'
        el.style.top = e.pageY - disy + 'px'
      }
      document.onmouseup = function() {
        document.onmousemove = document.onmouseup = null
      }
    }
    el.addEventListener('mouseup', (event) => {     //鼠标抬起后的事件，判断是拖拽还是点击
    let clientX = event.clientX;
    let clientY = event.clientY;
    if (clientX === clientOffset.clientX && clientY === clientOffset.clientY) {        //这里触发点击事件，将click方法移入这里
    let that = vnode.context        //通过vnode参数来获取this,此时的that就可以获取到js中的this
    } else { 
      return     //这里是拖拽事件
    }
  })}

export default drag;